/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
/**
 * Clamps a value between two inclusive limits
 *
 * @param value
 * @param min lower limit
 * @param max upper limit
 */
function tuiClamp(value, min, max) {
    ngDevMode && console.assert(!Number.isNaN(value));
    ngDevMode && console.assert(!Number.isNaN(min));
    ngDevMode && console.assert(!Number.isNaN(max));
    ngDevMode && console.assert(max >= min);
    return Math.min(max, Math.max(min, value));
}

/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
function tuiInRange(value, fromInclude, toExclude) {
    ngDevMode && console.assert(!Number.isNaN(value));
    ngDevMode && console.assert(!Number.isNaN(fromInclude));
    ngDevMode && console.assert(!Number.isNaN(toExclude));
    ngDevMode && console.assert(fromInclude < toExclude);
    return value >= fromInclude && value < toExclude;
}

/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
/**
 * Normalizes any number to an integer within inclusive range
 *
 * @param value
 * @param min lower inclusive integer
 * @param max upper inclusive integer
 * @return an integer between min and max inclusive
 */
function tuiNormalizeToIntNumber(value, min, max) {
    ngDevMode && console.assert(Number.isInteger(min));
    ngDevMode && console.assert(Number.isInteger(max));
    ngDevMode && console.assert(min <= max);
    if (Number.isNaN(value) || value <= min) {
        return min;
    }
    if (value >= max) {
        return max;
    }
    return Math.round(value);
}

/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
/**
 * Rounds a number to the closest value in a fixed discrete series
 *
 * @param value
 * @param quantum series step
 */
function tuiQuantize(value, quantum) {
    ngDevMode && console.assert(Number.isFinite(value));
    ngDevMode && console.assert(Number.isFinite(quantum));
    ngDevMode && console.assert(quantum > 0);
    const remainder = value % quantum;
    return remainder < quantum / 2 ? value - remainder : value + quantum - remainder;
}

/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
const MAX_PRECISION = 292;
/**
 * Rounding number to the set precision
 *
 * @param value
 * @param precision number of digits in a float part
 * @param func rounding function (round, floor, ceil)
 */
function calculate(value, precision, func) {
    if (value === Infinity) {
        return value;
    }
    ngDevMode && console.assert(!Number.isNaN(value), 'Value must be number');
    ngDevMode && console.assert(Number.isInteger(precision), 'Precision must be integer');
    precision = Math.min(precision, MAX_PRECISION);
    const [significand, exponent = ''] = `${value}`.split('e');
    const roundedInt = func(Number(`${significand}e${Number(exponent) + precision}`));
    /**
     * TODO: use BigInt after bumping Safari to 14+
     */
    ngDevMode &&
        console.assert(Number.isSafeInteger(roundedInt), 'Impossible to correctly round such a large number');
    const processedPair = `${roundedInt}e`.split('e');
    return Number(`${processedPair[0]}e${Number(processedPair[1]) - precision}`);
}
function tuiRound(value, precision = 0) {
    return calculate(value, precision, Math.round);
}
function tuiCeil(value, precision = 0) {
    return calculate(value, precision, Math.ceil);
}
function tuiFloor(value, precision = 0) {
    return calculate(value, precision, Math.floor);
}
function tuiTrunc(value, precision = 0) {
    return calculate(value, precision, Math.trunc);
}
function tuiIsSafeToRound(value, precision = 0) {
    return Number.isSafeInteger(Math.trunc(value * 10 ** precision));
}

function tuiRoundWith({ value, precision, method, }) {
    switch (method) {
        case 'ceil':
            return tuiCeil(value, precision);
        case 'floor':
            return tuiFloor(value, precision);
        case 'round':
            return tuiRound(value, precision);
        default:
            return tuiTrunc(value, precision);
    }
}

/**
 * Calculates sum of any number of passed arguments
 */
function tuiSum(...args) {
    return args.reduce((a, b) => a + b, 0);
}

function tuiToInt(bool) {
    return bool ? 1 : 0;
}

function tuiToInteger(value) {
    return parseInt(value, 10);
}

/**
 * Converts angle in degrees to radians
 */
function tuiToRadians(deg) {
    return (deg * Math.PI) / 180;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiCeil, tuiClamp, tuiFloor, tuiInRange, tuiIsSafeToRound, tuiNormalizeToIntNumber, tuiQuantize, tuiRound, tuiRoundWith, tuiSum, tuiToInt, tuiToInteger, tuiToRadians, tuiTrunc };

